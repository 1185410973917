import { getSensorData } from './api/getSensorData'
import { getSensorDataJson } from './json/getSensorDataJson'
import { getAccessDecision } from './api/getAccessDecision'

export async function requestSensorData(id, sensorId, roomId) {
  const getSensorDataRequest = !roomId ? getSensorDataJson : getSensorData

  /** アクセス可否を確認する */
  const accessDecisionResponse = await getAccessDecision(id)
  if (accessDecisionResponse.isError) {
    // 取得できなければ404
    return new Response({
      status: 404,
    })
  }
  // URLのオリジナルIDを退避
  let org_id = id;
  // 取得しなおしたIDで置き換える
  id = accessDecisionResponse.data.pageUrl;

  return await getSensorDataRequest(id, sensorId, roomId)
}
